import React from 'react'
import {graphql, useStaticQuery} from "gatsby";
import { hierarchicalMenu, Link } from "../../plugins/gatsby-plugin-atollon"

const Footer = () => {

  const languageKey = 'au'

  const staticData = useStaticQuery(graphql`
    query DesktopFooterData {
      au: allDatoCmsFooterMenuItem {
        nodes {
          id
          treeParent {
            id
          }
          title
          position
          link {
            ...LinkWithoutTitleProps
          }
          hideForLocale
        }
      }
    }
  `);
  const menuForLang = staticData[languageKey].nodes
  const menu = hierarchicalMenu(menuForLang)

  return (
    <footer className='footer'>
      <nav className='footer__nav'>
        { menu.childMenu &&
          <ul>
            { menu.childMenu.map((item, index) => (
              <li key={index}><Link {...item.link} target='_blank' rel='noopener noreferrer'>{item.title}</Link></li>
            )) }
          </ul>
        }
        <ul>
          <li>Brand. <Link to='https://foxhat.melbourne/' target="_blank" rel="noopenner noreferrer">Foxhat</Link>  |  Website. <Link to='https://atollon.com.au?utm_source=andgray&utm_medium=website&utm_campaign=referrer&utm_term=realestate' target="_blank" rel="noopenner noreferrer">Atollon</Link></li>
        </ul>
      </nav>
    </footer>
  )
}

export default Footer
